import { Alert, AlertIcon } from "@chakra-ui/alert"
import { Button } from "@chakra-ui/button"
import { Box, Center, Container, Flex, Heading, Text } from "@chakra-ui/layout"
import axios from "axios"
import { Link } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

export default function Auth({ onAuthed }) {
  const sendActionToStore = useDispatch()
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")

  let apiURL = () => {
    if (window.location) {
      if (
        window.location.host === "mode.janmeininghaus.com" ||
        window.location.host === "preview.mode.janmeininghaus.com"
      ) {
        return "https://api.mode.janmeininghaus.com/api/live"
      } else if (
        window.location.host === "staging.mode.janmeininghaus.com" ||
        window.location.host === "preview.staging.mode.janmeininghaus.com"
      ) {
        return "https://api.mode.janmeininghaus.com/api/staging"
      } else if (
        window.location.host === "mobilitydesignguide.org" ||
        window.location.host === "preview.mobilitydesignguide.org"
      ) {
        return "https://api.mode.janmeininghaus.com/api/prod"
      } else {
        return "https://api.mode.janmeininghaus.com/api/staging"
      }
    }
  }

  useEffect(() => {
    if (!localStorage) return
    let token = localStorage.getItem("accessToken")
    console.log(token)
    if (token !== null && token !== undefined) {
      setLoading(true)
      axios
        .post(apiURL() + "/oauth/introspection", new URLSearchParams({}), {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(({ data }) => {
          setLoading(false)
          if (data.active) {
            setLoggedIn(true)
            console.log(data)
            sendActionToStore({ type: "auth/accessToken", payload: token })
            setErrorMessage("")
            onAuthed(data)
          } else {
            setLoggedIn(false)
            setErrorMessage("Session ist abgelaufen")
            localStorage.removeItem("accessToken")
            sendActionToStore({ type: "auth/accessToken", payload: "" })
          }
        })
        .catch(() => {
          setErrorMessage("Authentifizierung ist abgelaufen.")
          sendActionToStore({ type: "auth/accessToken", payload: "" })
          localStorage.removeItem("accessToken")
          setLoggedIn(false)
          setLoading(false)
        })
    } else {
      let params = new URLSearchParams(document.location.search.substring(1))
      let code = params.get("code")
      if (code !== "" && code !== null) {
        setLoading(true)
        let data = new URLSearchParams({
          grant_type: "authorization_code",
          code: code,
          client_id: process.env.GATSBY_CLIENT_ID,
          client_secret: process.env.GATSBY_CLIENT_SECRET,
        })
        axios
          .post(apiURL() + "/oauth/token", data, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(({ data }) => {
            setLoading(false)
            if (localStorage) {
              localStorage.setItem("accessToken", data.access_token)
              sendActionToStore({
                type: "auth/accessToken",
                payload: data.access_token,
              })
              setLoggedIn(true)
              setErrorMessage("")
              onAuthed(data)
            }
          })
          .catch(() => {
            setLoading(false)
            setErrorMessage("Authorisierungscode ist falsch oder abgelaufen.")
            sendActionToStore({ type: "auth/accessToken", payload: "" })
            localStorage.removeItem("accessToken")
            setLoggedIn(false)
          })
      } else {
        setLoading(false)
      }
    }
  }, [])

  return (
    <Flex direction="column">
      {loggedIn ? (
        <p>Logged IN</p>
      ) : (
        <Center w="100vw" h="100vh">
          <Box w="500px" h="700px">
            {loading ? (
              <Center w="500px" h="700px" flexDirection="column">
                <Container centerContent>
                  <Heading as="h3" fontSize="2xl">
                    Einen Moment bitte ...
                  </Heading>
                </Container>
              </Center>
            ) : (
              <Center w="500px" h="700px" flexDirection="column">
                <Container centerContent>
                  <Heading as="h3" fontSize="2xl">
                    Klicke um den Szenendesigner zu starten
                  </Heading>
                </Container>
                <Button colorScheme="blue">
                  <a
                    href={
                      process.env.GATSBY_CMS_URL +
                      "/oauth/authorize?" +
                      new URLSearchParams({
                        client_id: process.env.GATSBY_CLIENT_ID,
                        response_type: "code",
                      })
                    }
                  >
                    Start
                  </a>
                </Button>
                {errorMessage !== "" && (
                  <Alert status="error" marginTop="4">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
              </Center>
            )}
          </Box>
        </Center>
      )}
    </Flex>
  )
}
