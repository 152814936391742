import axios from "axios"
import React, { useCallback } from "react"
import { useSelector } from "react-redux"

export default function useAPI() {
  const ACCESS_TOKEN = useSelector(state => state.accessToken)

  /**
   *
   * POSTS Data to a specified Endpoint. Data should be Stringified.
   *
   * @param {string} endpoint Endpoint for the API (e.g. /projektziele/351)
   * @param {string} data Stringified JSON Data body.
   *
   */
  const postRequest = async (endpoint, data) => {
    let config = {
      url: process.env.GATSBY_CMS_URL + "/wp-json/wp/v2" + endpoint,
      method: "post",
      headers: {
        Authorization: "Bearer " + ACCESS_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(config)
      .then(({ data }) => data)
      .catch(err => console.error(err))
  }

  /**
   *
   * Gets Data from a specified Endpoint
   *
   * @param {string} endpoint Endpoint for the API (e.g. /projektziele/351)
   *    @returns {Promise}
   */
  const getRequest = async endpoint => {
    let config = {
      url: process.env.GATSBY_CMS_URL + "/wp-json/wp/v2" + endpoint,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
    return await axios(config)
      .then(({ data }) => data)
      .catch(err => console.error(err))
  }

  return {
    postRequest,
    getRequest,
  }
}
