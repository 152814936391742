import React, { useState, useEffect, useRef } from "react"
import { useStore, Provider, useDispatch, useSelector } from "react-redux"
import Map from "../components/Map/Map"
import PSDSidebar from "../components/ProjectSceneDesigner/PSDSidebar"
import { moveCameraToPosition } from "../components/Animation/CameraAnimation"
import {
  Heading,
  Box,
  Flex,
  Text,
  Center,
  AspectRatio,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  HStack,
  Skeleton,
  Menu,
  Spacer,
  Select,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
} from "@chakra-ui/react"
import Auth from "../components/Buttons/Auth"
import useAPI from "../hooks/useAPI"
import { graphql, useStaticQuery } from "gatsby"

const ProjectSceneDesigner = () => {
  // Store
  const store = useStore()
  const sendActionToStore = useDispatch()
  const [authed, setAuthed] = useState(false)
  const [currentPostID, setCurrentPostID] = useState(null)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [data, setData] = useState(null)

  const { getRequest } = useAPI()

  const staticWPLocations = useStaticQuery(
    graphql`
      query {
        allWpLocation {
          nodes {
            name
            id
            databaseId
            acf_location {
              location {
                x
                y
              }
            }
          }
        }
      }
    `
  )

  const locationData = staticWPLocations.allWpLocation.nodes

  // * Set background color to gray
  useEffect(() => {
    sendActionToStore({
      type: "change/map/groundColor",
      payload: "#dddddd",
    })
  }, [])

  // * Send portal ref to store
  const portalRef = useRef()
  sendActionToStore({
    type: "change/markerContainerPortal",
    payload: portalRef,
  })

  // * Get ID from URL / Local
  useEffect(() => {
    let params = new URLSearchParams(document.location.search.substring(1))
    let id = params.get("id")

    if (id !== null && id !== "") {
      // set local id if id is provided
      localStorage.setItem("id", id)
      setCurrentPostID(id)
    } else {
      // if id is not provided, check if localstorage already has one
      let localID = localStorage.getItem("id")
      if (localID !== undefined && localID !== "") {
        setCurrentPostID(localID)
      } else {
        // DO SOMETHING WHEN ID IS NOT PROVIDED VIA URL NOR LOCALSTORAGE
      }
    }
  }, [])

  // Example Data
  // so sollte das Daten Element ungefähr aussehen.

  //
  //   {
  //     "fields": { // WICHTIG!!! Das Feld fields ist wichtig, da ACF das benötigt um die daten anzupassen.
  //         "test_scene_picker": [
  //       {
  //         "x": "5",
  //         "y": "0",
  //         "name": "Markus",
  //         "type": {
  //           "ID": 515,
  //           "post_author": "1",
  //           "post_date": "2021-04-13 12:08:06",
  //           "post_date_gmt": "2021-04-13 12:08:06",
  //           "post_content": "",
  //           "post_title": "Repräsentant",
  //           "post_excerpt": "",
  //           "post_status": "publish",
  //           "comment_status": "closed",
  //           "ping_status": "closed",
  //           "post_password": "",
  //           "post_name": "repraesentant",
  //           "to_ping": "",
  //           "pinged": "",
  //           "post_modified": "2021-04-13 12:08:06",
  //           "post_modified_gmt": "2021-04-13 12:08:06",
  //           "post_content_filtered": "",
  //           "post_parent": 0,
  //           "guid": "https://cms.mode.janmeininghaus.com/?post_type=map_icon&#038;p=515",
  //           "menu_order": 0,
  //           "post_type": "map_icon",
  //           "post_mime_type": "",
  //           "comment_count": "0",
  //           "filter": "raw"
  //         },
  //         "title": "Florian, 8 Jahre. Mit seinem Bike unterwegs",
  //         "image": false,
  //         "param": "Florian"
  //       }
  //     ]
  //     }
  // }

  // Auskommentieren um Beispiel Daten abfrage zu erstellen

  useEffect(() => {
    if (currentPostID !== null && !dataLoaded) {
      // POST Request
      // postRequest(
      //   `/projektziele/${currentPostID}`,
      //   JSON.stringify({ title: "TEST" })
      // ).then(res => console.log(res))
      // GET Request
      getRequest(`/projektziele/${currentPostID}`).then(res => setData(res))
      if (data !== null) {
        setDataLoaded(true)
      }
      console.log("DATA LOADED")
      console.log("Data:", data)
    }
  }, [currentPostID, data, dataLoaded, getRequest])

  // AspectRatio = 16 / canvasRatio
  const [canvasRatio, setCanvasRatio] = useState({ x: 16, y: 9 })

  function handleSliderChange(value) {
    switch (value) {
      case 1:
        setCanvasRatio({ x: 9, y: 16 })
        break
      case 2:
        setCanvasRatio({ x: 16, y: 12 })
        break
      case 3:
        setCanvasRatio({ x: 16, y: 9 })
        break
      default:
        setCanvasRatio({ x: 16, y: 9 })
    }
  }
  const [location, setLocation] = useState(locationData[0])
  const [currentLocation, setCurrentLocation] = useState({
    x: location.acf_location.location.x,
    y: location.acf_location.location.y,
  })

  console.log("locationData", locationData)

  useEffect(() => {
    // * Wurde eine Szene schon gewählt?
    if (data !== null) {
      const selectedLocation = locationData.find(
        s => s.name === data.acf.location.name
      )
      if (selectedLocation !== undefined) {
        setCurrentLocation({
          x: selectedLocation.acf_location.location.x,
          y: selectedLocation.acf_location.location.y,
        })
        setLocation(selectedLocation)
        sendActionToStore({
          type: "change/activeLocation",
          payload: {
            x: selectedLocation.acf_location.location.x,
            y: selectedLocation.acf_location.location.y,
          },
        })
      }
      console.log("Ausgewählte Szene: ", selectedLocation, data)
    } else {
      console.log("Keine Szene ausgewählt")
    }
  }, [data, locationData, sendActionToStore])

  useEffect(() => {
    moveCameraToPosition(
      sendActionToStore,
      currentLocation.x,
      currentLocation.y,
      0,
      90
    )
  }, [currentLocation, sendActionToStore])

  // Camera to current Project
  // TODO: Change to project location from WP

  // * Location change
  function handleLocationChange(e) {
    const selectedLocation = locationData.find(s => s.name === e.target.value)
    if (selectedLocation !== undefined) {
      console.log("Move camera to ", selectedLocation.name)
      setCurrentLocation({
        x: selectedLocation.acf_location.location.x,
        y: selectedLocation.acf_location.location.y,
      })
      setLocation(selectedLocation)
    }
  }

  const [visibleScene, setVisibleScene] = useState({
    topVisible: true,
    bottomVisible: true,
  })

  const visibleState = useSelector(state => state.projectScene)

  useEffect(() => {
    sendActionToStore({
      type: "change/projectScene",
      payload: visibleScene,
    })
  }, [visibleScene, sendActionToStore])

  console.log("Visible Scene State:", visibleState)

  const scene_top = "Szene 1 und Szene 2"
  const scene_bottom = "Nur Szene 2"

  const handleSceneSwitch = e => {
    let inputValue = e.target.value
    if (inputValue === scene_top) {
      setVisibleScene({ topVisible: true, bottomVisible: false })
    } else {
      setVisibleScene({ topVisible: true, bottomVisible: true })
    }
  }

  const [refresh, setRefresh] = useState(0)

  const refreshCallback = () => {
    setRefresh(refresh => refresh + 1)
  }

  return (
    <>
      {!authed ? (
        <Auth onAuthed={data => setAuthed(true)} />
      ) : (
        <>
          <Center
            position="absolute"
            top="0"
            left="0"
            h="100vh"
            w="100vw"
            bg="white"
            display={dataLoaded ? "none" : "inherit"}
          >
            Projektszene laden ...{" "}
          </Center>
          <Provider store={store}>
            <Box className="font-sans" id="psd">
              <Flex w="100%">
                <Box position="fixed" bg="gray.800" w="66%" h="100vh">
                  <Box zIndex="20" top="32px" h="16px" boxSizing m="32px">
                    <HStack color="white" fontWeight="bold">
                      <Box w="33%">Mobile</Box>
                      <Box w="33%" textAlign="center">
                        Tablet
                      </Box>
                      <Box w="33%" textAlign="right">
                        Desktop
                      </Box>
                    </HStack>
                    <Slider
                      onChange={handleSliderChange}
                      defaultValue={3}
                      min={1}
                      max={3}
                      step={1}
                    >
                      <SliderTrack bg="gray.900">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg="black" />
                      </SliderTrack>
                      <SliderThumb boxSize={6} />
                    </Slider>
                  </Box>
                  <div ref={portalRef} id="portal" />
                  <AspectRatio
                    m="32px"
                    mt="96px"
                    position="fixed"
                    width="60%"
                    maxH="calc(100% - 188px)"
                    transitionDuration="0.2s"
                    ratio={canvasRatio.x / canvasRatio.y}
                  >
                    <>
                      <Map psd />
                    </>
                  </AspectRatio>
                  <Box
                    pos="fixed"
                    bottom="8px"
                    w="66%"
                    display="flex"
                    justifyContent="center"
                  >
                    <Box w="280px" bg="white" rounded="lg">
                      <Text fontWeight="bold" mt="1" mb="1" textAlign="center">
                        Vorschau
                      </Text>
                      <Menu>
                        <Select onChange={handleSceneSwitch}>
                          <option>{scene_bottom}</option>
                          <option>{scene_top}</option>
                        </Select>
                      </Menu>
                    </Box>
                  </Box>
                  {/* <GUI /> */}
                </Box>
                {dataLoaded && data.acf !== undefined ? (
                  <Box ml="67%" w="34%" minH="100vh" minW="400px">
                    <Box p="8px">
                      <Heading mt="4" fontSize="lg">
                        <Skeleton isLoaded={dataLoaded}>
                          {data !== null ? data.title.rendered : ""}
                        </Skeleton>
                      </Heading>
                      <Accordion allowToggle>
                        <AccordionItem border="0">
                          <AccordionButton p="1">
                            <Text>
                              🛈 Workflow zum Erstellen einer Szene mit Icons
                            </Text>
                            <Spacer />
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel>
                            <ol style={{ listStyleType: "decimal" }}>
                              <li>
                                Aus dem Dropdown Menü einen Szeneort der Karte
                                wählen.
                              </li>
                              <li>
                                In der Vorschau durch Klicken eine Stelle in der
                                Szene festlegen (Pin mit Kreis erscheint)
                              </li>
                              <li>
                                Durch den Button <i>Objekt hinzufügen</i> die
                                weitere Bearbeitung des Objektes beginnen,
                                Eingaben zwischenspeichern oder prüfen. Objekt
                                platzieren.
                              </li>
                              <li>
                                Nach Bearbeitung aller Einträge speichern. Auf
                                Bestätigung warten (kann einige Sekunden
                                dauern).
                              </li>
                              <li>
                                Um zum Beitrag in Wordpress zurückzukehren:
                                "Zurück zu Wordpress" klicken.
                              </li>
                            </ol>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem border="0">
                          <AccordionButton p="1">
                            <Text>🛈 Verschieben einzelner Buttons</Text>
                            <Spacer />
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel>
                            <ol style={{ listStyleType: "decimal" }}>
                              <li>
                                In der Vorschau an die gewünschte neue Stelle
                                des Objektes klicken (Pin mit Kreis erscheint)
                              </li>
                              <li>
                                In der Liste das gewünschte Objekt suchen und
                                verschieben klicken.
                              </li>
                            </ol>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <Text fontWeight="bold" mt="2">
                        In welcher Szene findet das Projekt statt?
                      </Text>
                      <Menu>
                        <Select
                          onChange={e => handleLocationChange(e)}
                          bg="white"
                          placeholder={location.name}
                        >
                          {locationData.map(sceneLocation => {
                            return (
                              <option key={sceneLocation.id}>
                                {sceneLocation.name}
                              </option>
                            )
                          })}
                        </Select>
                      </Menu>
                    </Box>
                    <PSDSidebar
                      location={location}
                      wpMarkers={data.acf.repeater_scene}
                      currentPostID={currentPostID}
                      backLink={`${process.env.GATSBY_CMS_URL}/wp-admin/post.php?post=${currentPostID}&action=edit`}
                      refreshCallback={refreshCallback}
                    />
                  </Box>
                ) : (
                  <></>
                )}
              </Flex>
            </Box>
          </Provider>
        </>
      )}
    </>
  )
}

export default ProjectSceneDesigner
